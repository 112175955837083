import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { FetchCurrentUserGQL, User, UserRole } from 'graphql/generated';
import { Observable, of, ReplaySubject, tap } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private _httpClient = inject(HttpClient);
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);
    private fetchCurrentUserGQL = inject(FetchCurrentUserGQL);
    private userService = inject(UserService)
    user: User;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.paramMap.subscribe(
            p => {
                this.setupNavigation();
            }
        )
        // this.loadCurrentUser();
        this.setupNavigation();
    }

    loadCurrentUser() {
        this.fetchCurrentUserGQL.fetch({}, { fetchPolicy: 'no-cache' }).subscribe(
            result => {
                this.user = result.data.fetchCurrentUser as User;
                // this.setupNavigation();
            }
        )
    }

    navigationItemList: FuseNavigationItem[] = [];

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        let navigation: Navigation = {
            compact: this.navigationItemList,
            default: this.navigationItemList,
            futuristic: this.navigationItemList,
            horizontal: this.navigationItemList,
        };
        this._navigation.next(navigation);
        return of(navigation);
    }

    setupNavigation() {
        this.fetchCurrentUserGQL.fetch({}, { fetchPolicy: 'no-cache' }).subscribe(
            result => {
                this.user = result.data.fetchCurrentUser as User;
                this.navigationItemList = [
                    {
                        id: 'reporting',
                        title: 'Reportings',
                        type: 'basic',
                        icon: 'heroicons_outline:chart-pie',
                        link: '/reportings',
                        hidden: (i) => this.user.role !== UserRole.Admin
                    },
                    {
                        id: 'user',
                        title: 'Utilisateurs',
                        type: 'basic',
                        icon: 'heroicons_outline:users',
                        link: '/users',
                        hidden: (i) => this.user.role !== UserRole.Admin
                    },
                    {
                        id: 'settings',
                        title: "Paramétrage",
                        type: 'collapsable',
                        icon: 'heroicons_outline:adjustments-horizontal',
                        hidden: (i) => this.user.role !== UserRole.Admin,
                        children: [
                            {
                                id: 'client',
                                title: "Lieux de visite",
                                type: 'basic',
                                icon: 'heroicons_outline:briefcase',
                                link: '/clients'
                            },
                            {
                                id: 'entryPoint',
                                title: "Points d'entrée",
                                type: 'basic',
                                icon: 'heroicons_outline:home',
                                link: '/entry-points'
                            },
                            {
                                id: 'services',
                                title: 'Services',
                                type: 'basic',
                                icon: 'heroicons_outline:computer-desktop',
                                link: '/services'
                            },
                        ]
                    },
                    {
                        id: 'visite',
                        title: 'Visites',
                        type: 'collapsable',
                        icon: 'heroicons_outline:calendar',
                        children: [
                            {
                                id: 'visite',
                                title: 'Visites',
                                type: 'basic',
                                icon: 'heroicons_outline:calendar',
                                link: '/visites',
                                hidden: (i) => this.user.role === UserRole.Vigile
                            },
                            {
                                id: 'visitsControl',
                                title: 'Contrôle des Visites',
                                type: 'basic',
                                icon: 'heroicons_outline:shield-check',
                                link: '/visits-control',
                                hidden: (i) => this.user.role === UserRole.Collaborator
                            },
                        ]
                    },
                    {
                        id: 'travel',
                        title: 'Contrôle Fiche de voyage',
                        type: 'basic',
                        icon: 'heroicons_outline:chart-pie',
                        link: '/travel',
                        hidden: (i) => this.user.role === UserRole.Collaborator
                    },
                ]
                this.get();
            }
        )

    }
}
